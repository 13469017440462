;(function($) {
	
	var stickyNavSetup = function() {
		var nav = $('[data-fixed-masthead]');
		var navHeight = nav.outerHeight();

		nav.wrap('<div id="masthead-wrapper"></div>');
		$('#masthead-wrapper').height(navHeight);
	}

	var stickyNavHeightFix = function() {
		var nav = $('[data-fixed-masthead]');
		var navHeight = nav.outerHeight();
		$('#masthead-wrapper').height(navHeight);
	}

	var stickyNavScroll = function() {
		if ( $('#masthead-wrapper').length === 0 ) { return; }
		var distanceToScroll = $('#masthead-wrapper').offset().top;
		var distanceScrolled = $(window).scrollTop();
		if ( distanceScrolled >= distanceToScroll ) {
			$('html').addClass('masthead-is-stuck');
		} else {
			$('html').removeClass('masthead-is-stuck');
		}
	}

	$(document).ready(function() {
		stickyNavSetup();
	});

	$(window).scroll(function() {
		window.requestAnimationFrame(stickyNavScroll);
	});

	$(window).resize(function() {
		stickyNavHeightFix();
		window.requestAnimationFrame(stickyNavScroll);
	});

})(jQuery);