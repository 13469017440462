;(function($) {

	$('.js-testimonials').slick({
		fade: true,
		dots: true,
		arrows: false
	});

	$('.js-backgrounds').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		fade: true,
		dots: true,
		arrows: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
	});

})(jQuery);