(function($) {

	$.fn.bcorePluginTemplate = function(args) {

		var instance = null;
		var options = {};

		var defaults = {
			var1: 1,
			var2: 2,
			success: null
		}

		$.extend(options, defaults, args);

		var Plugin = {
			init: function(instance, options) {
				options.success({
					instance: instance,
					options: options
				});
			}
		}

		return this.each(function(index) {
			instance = $(this);
			Plugin.init(instance, options);
		});
	}

})(jQuery);